@use '../designSystem/common/sassVars' as *;

.darkThemeContent {
	--time-color: #fff;
	--border-color: #707070;
	--link-color: #fff;
	--view-all-background: var(--grey-800);
	--view-all-color: #fff;

	background-color: var(--grey-800);
	border-radius: var(--rad);
	color: #fff;
	padding: 20px;

	h2 {
		margin: 0 0 17px;
		color: #fff;
	}

	@media (min-width: $scr-md-min) {
		padding: 20px 30px 30px;
	}

	.ds-mediaList--exclusive {
		--desktop-grid-template: 1fr 1fr;
	}

	.navMore {
		box-shadow: 0 -1px 0 inset var(--border-color);
	}

	.ds-viewAll {
		border: 1px solid var(--border-color);

		&:hover {
			border-color: #fff;
			color: #fff;
		}
	}
}
