@use "../../designSystem/common/sassVars" as *;

.playerWidget {
	background-color: var(--grey-100);
	margin-bottom: 25px;
	padding: 25px;

	@media (max-width: $scr-sm) {
		padding: 20px;
		margin-left: -20px;
		margin-right: -20px;
	}

	&>header {
		&>p {
			margin: 0 0 10px;
			color: var(--grey-700);
			font-size: 14px;
			line-height: 22px;
		}
	}

	&>footer {
		margin-top: 25px;
		text-align: center;
	}

	&__explain {
		font-size: 10px;
		text-align: left !important;
		line-height: 16px;
		color: var(--grey-700);
		margin: 15px 0 -10px;
	}

	.playerWidget__table,
	.playerWidget__list {
		display: grid;
		row-gap: 8px;

		&>a {
			background-color: #fff;
			color: #000;
			display: grid;
			border-radius: 4px;

			&:hover {
				box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
			}
		}
	}

	figure {
		position: relative;
		margin: 0;

		img {
			vertical-align: top;
			max-width: none;

			&.country {
				background-color: #fff;
				box-shadow: 0 0 0 1px var(--flag-outline);
			}
		}

		.photo {
			border-radius: 50%;
		}
	}
}
