@use '../../designSystem/common/sassVars' as *;

%otc-mobile-carousel {
	@media (max-width: $web-width) and (min-width: $scr-lg-min) {
		width: calc(100vw - 390px);
	}

	@media (max-width: $scr-lg) and (min-width: $scr-md-min) {
		width: calc(100vw - 360px);
	}


	.otc {
		@media (max-width: $scr-md) {
			margin-left: -20px;
			margin-right: -20px;

			tbody {
				padding: 0 20px 0 0;
			}

			tr {
				margin: 0 0 0 20px !important;
			}
		}

		@media (max-width: $scr-xlg) {
			overflow-x: scroll;
			padding: 15px 0 10px;
			scroll-snap-type: x mandatory;

			tbody {
				display: inline-flex !important;

				tr {
					flex: 1 0 auto;
					margin: 0 20px 0 0;
					scroll-snap-align: center;
					width: 300px;

					&.hideRow {
						max-height: 500px;
						width: 0;
						margin-left: -30px !important;
						margin-right: 0 !important;
						padding: 20px 15px 15px;
						transition:
							width .2s ease-in-out,
							opacity .2s ease-in-out,
					}
				}
			}
		}
	}
}
