@use "../../designSystem/common/sassVars" as *;
@use "widget__base";
@use "widgetList__base";

.playerWidget {
	&__scrollWrapper {
		@media (max-width: $scr-sm) {
			margin: 0 -20px;
			overflow-x: scroll;
			scroll-snap-type: x mandatory;
			padding-bottom: 10px;
		}
	}

	&--scrollable {
		.playerWidget__list {
			grid-template-columns: repeat(5, 1fr);

			@media (max-width: $scr-sm) {
				padding: 0 20px 0 0;
				display: inline-flex !important;
				column-gap: 0;
			}

			&>a {
				@media (max-width: $scr-sm) {
					scroll-snap-align: center;
					margin-left: 20px;
					width: 175px;
					flex: 1 0 auto;
				}
			}
		}
	}
}
