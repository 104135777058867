@use "../designSystem/common/sassVars" as *;

.ds-saferGambling {
	border: 1px solid;
	border-radius: var(--rad);
	min-height: 80px;
	position: relative;
	padding: 30px 40px;
	overflow: hidden;
	display: flex;
	align-items: center;
	container-type: inline-size;

	@media (max-width: $scr-sm) {
		padding: 40px 16px 16px;
		justify-content: center;
	}

	&__sprite {
		position: absolute;

		&--1 {
			right: 0;
			top: 0;


			@media (max-width: $scr-sm) {
				display: none;
			}
		}

		&--2 {
			left: 310px;
			top: 0;

			@media (max-width: $scr-sm) {
				left: 80px;
			}
		}

		&--3 {
			left: 0;
			top: 0;
		}

		&--4 {
			right: 0;
			top: 0;
			display: none;

			@media (max-width: $scr-sm) {
				display: block;
			}
		}
	}

	&__main {
		display: grid;
		grid-template-areas:
			"logo checkboxes cta";
		align-items: center;
		justify-content: center;
		column-gap: 40px;

		@container (max-width: #{$scr-sm}) {
			grid-template-areas:
			"logo cta"
			"checkboxes checkboxes";
			row-gap: 20px;
		}

		@media (max-width: $scr-sm) {
			grid-template-areas:
			"logo cta"
			"checkboxes checkboxes";
			grid-template-columns: 110px auto;
			gap: 16px 20px;
			font-size: 8px;
		}

		&__logo {
			grid-area: logo;
			width: 96px;

			@media (max-width: $scr-sm) {
				width: 110px;
			}
		}

		&__checkboxes {
			grid-area: checkboxes;
			display: flex;
			column-gap: 24px;

			@media (max-width: $scr-sm) {
				column-gap: 16px;
			}

			& > div {
				display: flex;
				align-items: center;
				column-gap: 4px;

				& > img {
					width: 21px;

					@media (max-width: $scr-sm) {
						width: 12px;
					}
				}
			}
		}

		&__cta {
			grid-area: cta;
			text-decoration: underline;
			margin-right: 60px;

		}
	}

	&:hover .ds-saferGambling__main__cta {
		text-decoration: none;
	}
}
