@use "../../designSystem/common/sassVars" as *;

.ds-mediaList {
	// between: min (smaller) iki max (bigger)

	@media (min-width: $scr-xs-min) and (max-width: $scr-sm),
	(min-width: $scr-md-min) and (max-width: $scr-lg) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		gap: 20px 10px;
	}

	@media (min-width: $scr-sm-min) and (max-width: $scr-md),
	(min-width: $scr-lg-min) {
		grid-template-columns: repeat(4, 1fr);
		gap: 20px 10px;

	}

	@media (min-width: $scr-xlg-min) {
		gap: 20px 30px;
	}

	@media (min-width: $scr-xs-min) {
		display: grid;
	}

	@media (max-width: $scr-xs) {
		.ds-mediaList__item:not(:first-child) {
			border-top: 1px solid var(--border-color, var(--grey-400));
			padding-top: 20px;
			margin-top: 20px;
		}
	}
}
