@use "../../designSystem/common/sassVars" as *;
@use "../../designSystem/exports/videoPlayButton";
@use "../../designSystem/exports/imageAspectRatio";

.ds-mediaList__item {
	--play-button-size: 26px;

	display: grid;
	align-items: flex-start;

	&:not(.ds-mediaList__item--exclusive) {
		@media (max-width: $scr-xs) {
			grid-template-columns: 144px auto;
			column-gap: 10px;
		}

	}

	@media (min-width: $scr-xs-min) {
		row-gap: 10px;
		grid-template-rows: auto 1fr;
	}


	figure {
		--aspect-ratio-h: 480;
		--aspect-ratio-w: 720;

		@extend %image-aspect-ratio;
	}

	&.ds-mediaList__item--video figure {
		img {
			position: static;
		}

		&::after {
			content: normal;
		}

	}

	&__img {
		vertical-align: top;

		&--fallback {
			box-shadow: 0 0 0 1px var(--grey-200);
		}
	}

	&__text {
		font-size: 12px;
		display: grid;
		align-items: flex-start;
		gap: 7px;
	}

	&__label {
		line-height: 19px;
		background-color: rgba(#0069D0, .08);
		padding: 0 8px;
		justify-self: flex-start;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		color: var(--link-color, #000);
	}

	&__title {
		font-size: 14px;
		font-weight: 600;
		color: var(--link-color, #000);

		@media (min-width: $scr-xs-min) {
			font-size: 16px;
		}
	}

	time {
		color: var(--time-color, var(--grey-700));
	}

	&__time {
		color: var(--time-color, var(--grey-700));

		span::after {
			content: '';
			display: inline-block;
			width: 3px;
			height: 3px;
			background-color: var(--time-color, var(--grey-700));
			border-radius: 50%;
			margin: 0 3px 0 5px;
			vertical-align: 2px;
		}
	}

	.ds-mediaList__item__title:hover {
		text-decoration: underline;
		color: var(--link-color, #000);
	}

	.videoPlayButton {
		@extend %video-play-button;
	}

}
