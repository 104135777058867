@use "../../designSystem/exports/baseTextFormat";

#livestreams {
	.ds-mediaList__item figure::after {
		padding-top: calc(628 / 1200 * 100%);
	}

	.ds-mediaList__item--exclusive {
		.ds-mediaList__item__title img {
			max-height: 26px;
		}
	}

	.ds-mediaList__item__summary {
		@extend %base-text-format;
	}

	.ds-mediaList__item__summary,
	.ds-mediaList__item__title {
		--link-color: #fff;
	}

	.ds-mediaList--exclusive__others {
		.ds-mediaList__item__summary {
			margin: 0;
		}

		.ds-mediaList__item__title img {
			max-height: 22px;
		}
	}

	.ds-mediaList__item__title {
		display: flex;
		align-items: center;
		column-gap: 4px;
	}
}
