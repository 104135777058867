.playerWidget {
	&__list {
		column-gap: 8px;

		.country {
			position: absolute;
			bottom: 5px;
			right: -2px;
			box-shadow: 0 0 0 1px var(--flag-outline);
		}
	}

}
