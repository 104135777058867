@use "../../designSystem/common/sassVars" as *;

.ds-mediaList--exclusive {
	grid-template-rows: auto;
	grid-template-columns: auto;
	gap: 0 30px;
	align-items: flex-start;

	--desktop-grid-template: 1.4fr 1fr;

	@media (min-width: $scr-sm-min) {
		grid-template-columns: var(--desktop-grid-template);
	}

	.ds-mediaList__item {
		grid-template-rows: auto;

		&:not(.ds-mediaList__item--exclusive) {
			grid-template-columns: 144px auto;
			column-gap: 10px;
		}

		&:not(:first-child, .ds-mediaList__item--exclusive) {
			border-top: 1px solid var(--border-color, var(--grey-400));
			padding-top: 20px;
			margin-top: 20px;
		}

		@media (max-width: $scr-sm) {
			&:not(.ds-mediaList__item--exclusive) {
				border-top: 1px solid var(--border-color, var(--grey-400));
				padding-top: 20px;
				margin-top: 20px;
			}
		}

		&--exclusive {
			row-gap: 10px;

			--play-button-size: 70px;

			@media (min-width: $scr-xs-min) and (max-width: $scr-sm) {
				grid-template-columns: 215px 1fr;
				column-gap: 10px;
				align-items: flex-start;
			}

			.ds-mediaList__item__title {
				font-size: 20px;
				line-height: 26px;
				-webkit-line-clamp: unset;

				@media (min-width: $scr-md-min) {
					font-size: 22px;
				}

				@media (min-width: $scr-xs-min) and (max-width: $scr-sm) {
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
			}

			.ds-mediaList__item__summary {
				display: none;
				color: var(--link-color, #000);

				@media (min-width: $scr-md-min) {
					display: block;
					margin: 5px 0;
					font-size: 16px;
					line-height: 26px;
				}
			}
		}
	}

}
