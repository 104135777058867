@use "../designSystem/common/sassVars" as *;
@use "../components/darkThemeContent";
@use "../editorjs/mediaList/base";
@use '../components/export/tabsContainer';
@use '../components/export/navMore' as *;
@use '../components/export/otcMobileCarousel';
@use '../players/components/scrollableCardsList';
@use "../../../node_modules/flickity/dist/flickity.css";
@use "../editorjs/saferGambling";

#main {
	@media (max-width: $scr-md) {
		.indexFog--casino + .indexFog--all {
			display: none;
		}
	}

	@media (min-width: $scr-md-min) {
		[data-group="latestNews"] + .indexFog, .indexFog--casino {
			display: none;
		}

		.virgutis:not([data-zone="sponsored:post"]) {
			margin-bottom: 40px;
		}
	}

	.twitterWidget,
	.topRooms {
		@media (max-width: $scr-md) {
			margin-bottom: 40px;
		}

		@media (min-width: $scr-md-min) {
			display: none;
		}
	}

	@media (max-width: $scr-md) {
		.conversionBanner {
			display: none;
		}
	}

	.ds-section footer {
		margin-top: -10px;
	}
}

.tabsContainer {
	@extend %tabs-container;
}

.navMore {
	@include nav-more;

	&>ul {
		border-color: #707070;

		&>li a {
			color: #fff;

			&.active {
				color: #fff;

				&::after {
					background-color: #fff;
				}
			}
		}
	}
}

.otcMobileCarousel {
	@media (max-width: $scr-xlg) {
		margin-top: 20px;
	}

	@extend %otc-mobile-carousel;

	@media (max-width: $scr-xs) {
		.otc {
			margin-bottom: 10px;
		}
	}
}

.playerWidget {
	background-color: transparent;
	margin: 0;
	padding: 0;

	.playerWidget__list {
		@media (min-width: $scr-lg-min) {
			column-gap: 30px;
		}

		a:hover {
			box-shadow: none;
			border-color: #000;
		}
	}

	&__tinyCard {
		border: 1px solid var(--grey-400);
		border-radius: var(--rad);
	}
}
