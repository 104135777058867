@use "../../designSystem/common/sassVars" as *;
@use "../../designSystem/mixins/bp" as *;

@mixin nav-more {
	& {
		box-shadow: 0 -1px 0 inset var(--grey-300);
		margin-bottom: 30px;
	}

	&:not(.stickyIconNavBar) {
		position: relative;

		&>ul>li>a {
			display: inline-block;
		}
	}

	&>ul {
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		max-width: 990px;
		padding-left: 0;
		margin-bottom: 0;

		&>li {
			padding: 0;
			display: inline-block;
			vertical-align: top;

			&.hidden {
				display: none !important;
			}

			a,
			label,
			.showDropdown {
				color: var(--grey-800);
				font-size: 16px;
				font-weight: 600;
				cursor: pointer;

				&.active {
					color: var(--primary-500);
				}

				&:not(.active):hover {
					color: var(--primary-900);
				}
			}

			&>label,
			&>.showDropdown {
				display: inline-block;
			}

			&>a,
			&>label,
			&>.showDropdown {
				line-height: 48px;
				z-index: 1;
			}

			&>a {
				margin-right: 30px;
				text-decoration: none;

				&.active {
					position: relative;

					&::after {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 2px;
						background-color: var(--primary-500);
					}
				}
			}

			&:last-child>a {
				margin-right: 0;
			}
		}
	}

	.navMore__dropdown {
		&.more {
			@media (min-width: $scr-xs-min) {
				position: relative;
			}

			ul {
				left: 0;

				a {
					white-space: nowrap;
				}
			}

		}

		.showDropdown {
			position: relative;
			cursor: pointer;
			padding-left: 14px;

			&::after {
				content: '';
				background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHdpZHRoPSIyNCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xMiA4YzEuMSAwIDItLjkgMi0ycy0uOS0yLTItMi0yIC45LTIgMiAuOSAyIDIgMnptMCAyYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnptMCA2Yy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4K');
				width: 24px;
				display: block;
				height: 48px;
				position: absolute;
				left: -8px;
				top: 0;
				background-repeat: no-repeat;
				background-position: center;
				filter: invert(29%) sepia(0%) saturate(4817%) hue-rotate(316deg) brightness(79%) contrast(83%);
			}
		}

		&.on {
			.showDropdown::after {
				filter: none;
			}

			ul {
				display: block;
				position: absolute;
				padding: 0;
				z-index: 2;
				border-radius: var(--rad);
				border: 1px solid var(--grey-300);
				background-color: #fff;
				top: 100%;
				text-align: left;
				white-space: normal;
				margin: -1px 0 0;

				@media (max-width: $scr-xs) {
					width: 100%;
				}


				li.active a {
					color: var(--primary-500);
				}

				a {
					padding: 10px 20px;
					display: flex;
					column-gap: 8px;

					&.child {
						text-transform: none;
						padding-left: 30px;
						padding-top: 5px;
						padding-bottom: 5px;
						margin-bottom: 5px;
					}

					&:hover {
						background-color: var(--grey-100);
					}
				}
			}
		}

		&:not(.navMore__dropdown--mobile, .more) {
			flex: 1;
			text-align: right;
		}

		ul {
			display: none;

		}



	}


	&.navMore--categories {
		.navMore__dropdown.categories {
			&>ul {
				width: 100%;
				column-count: 3;
				left: 0;
				padding: 20px 0;
				column-gap: 30px;

				@include bp-mobile {
					column-count: 2;
				}

				@media (max-width: $scr-xs) {
					column-count: 1;
				}

			}
		}

		&>ul>li {
			@media (max-width: $scr-sm) {
				&:not(.navMore__dropdown) {
					display: none;
				}
			}

			@media (min-width: $scr-sm-min) {
				&.navMore__dropdown--mobile {
					display: none;
				}
			}
		}

	}
}
